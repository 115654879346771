
/* Apply styles on small screens only */
@media (max-width: 767px) {
  .sidebar {
  left: -350px; 
  transition: left 0.3s ease-in-out; }
.sidebar.open {
  left: 0; }
}


